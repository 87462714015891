import React, { Fragment, useMemo } from "react";
import Grid from "@material-ui/core/Grid";
import RecIcon from "@material-ui/icons/VolumeUp";
import VideoIcon from "@material-ui/icons/Videocam";
import PictureIcon from "@material-ui/icons/Image";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { makeStyles } from "@material-ui/core/styles";
import groupBy from "lodash/groupBy";

import { CameraSensorKey } from "../types";

import { formatTime } from "../util";

interface Recording {
  sensor: CameraSensorKey;
  date: Date;
  value: string;
}

const ICON_MAP: Record<string, React.ReactElement> = {
  rec: <RecIcon />,
  video: <VideoIcon />,
  picture: <PictureIcon />,
};

const useStyles = makeStyles(() => ({
  oddItem: {
    backgroundColor: "#f4f4f4f4",
  },
}));

const Camera: React.FC<{
  loading: boolean;
  recordings: Recording[];
  onClick: (recording: Recording) => void;
}> = ({ loading, recordings, onClick }) => {
  const classes = useStyles();
  const recordingsByDate = useMemo(() => groupBy(recordings, (r) => r.date.toISOString()), [
    recordings,
  ]);
  if (!loading && recordings.length === 0) return <Typography>Nincs találat.</Typography>;
  return (
    <List>
      {Object.entries(recordingsByDate).map(([date, recordingGroup], i) => (
        <Fragment key={date}>
          <ListItem className={i % 2 === 1 ? classes.oddItem : ""}>
            <Grid container justify="space-between" alignItems="center">
              <Grid item xs={6}>
                {formatTime(date)}
              </Grid>
              <Grid item xs={6}>
                <Grid item container xs={6} justify="space-between" md={6}>
                  {recordingGroup.map((recording) => (
                    // If no xs value given, the auto computed value
                    // breaks the List height
                    <Grid key={recording.sensor} item xs={4}>
                      <IconButton onClick={() => onClick(recording)} color="primary" size="small">
                        {ICON_MAP[recording.sensor]}
                      </IconButton>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </ListItem>
          <Divider />
        </Fragment>
      ))}
    </List>
  );
};

export default Camera;
