import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Slide from "@material-ui/core/Slide";
import Typography from "@material-ui/core/Typography";
import { TransitionProps } from "@material-ui/core/transitions";
import { makeStyles } from "@material-ui/core/styles";

import { formatDateTime } from "../util";
import { Hive, Device } from "../types";

const DEVELOPMENT = process.env.NODE_ENV === "development";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function extendSrc(src: string): string {
  if (DEVELOPMENT) return `https://kamera.bee-box.io${src}`;
  return src;
}

const useStyles = makeStyles(() => ({
  container: {
    height: "100%",
    overflow: "hidden",
  },

  img: {
    objectFit: "scale-down",
    width: "100%",
    maxHeight: "100%",
  },

  video: {
    width: "100%",
    height: "100%",
  },
}));

const CameraSensorDialog: React.FC<{
  open: boolean;
  onClose: () => void;
  hive: Hive;
  device: Device;
  recording?: { date: Date | string; key: "rec" | "picture" | "video"; src: string };
}> = ({ open, onClose, hive, device, recording }) => {
  const classes = useStyles();
  if (!recording) return null;
  return (
    <Dialog fullScreen open={open} onClose={onClose} TransitionComponent={Transition}>
      <AppBar
        style={{
          position: "relative",
        }}
      >
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6">
            {hive.name} &gt; {device.name} &gt; {recording.key} &gt;{" "}
            {formatDateTime(recording.date)}
          </Typography>
        </Toolbar>
      </AppBar>

      {recording.key === "picture" && (
        <div className={classes.container}>
          <img
            src={extendSrc(recording.src)}
            alt={`Kép ${formatDateTime(recording.date)}`}
            className={classes.img}
          />
        </div>
      )}

      {recording.key === "rec" && (
        // eslint-disable-next-line jsx-a11y/media-has-caption
        <audio controls>
          <source src={extendSrc(recording.src)} type="audio/mpeg" />
          <span>A böngésző nem támogatja a hang lejátszást.</span>
        </audio>
      )}

      {recording.key === "video" && (
        <div className={classes.container}>
          {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
          <video loop controls className={classes.video}>
            <source src={extendSrc(recording.src)} type="video/mp4" />
            <span>A böngésző nem támogatja a videó lejátszást.</span>
          </video>
        </div>
      )}
    </Dialog>
  );
};

export default CameraSensorDialog;
