import { useState, useEffect } from "react";
import { isNil } from "lodash";

import { User } from "../types";

import useUser from "./useUser";
import useRequest from "./useRequest";

export default function useRememberMe(): [boolean, User | undefined] {
  const [user, setUser] = useUser();

  const [pending, setPending] = useState(true);
  const [{ loading, data }] = useRequest<User>("/session/");

  useEffect(() => {
    if (!loading) {
      if (!isNil(data)) {
        setUser(data);
      }
      setPending(false);
    }
  }, [loading, data, setUser]);

  return [pending, user];
}
