import React, { useState, createContext } from "react";

import { User } from "../types";

export type SetUser = (user: User) => void;
export type UserContextType = [User | undefined, (user?: User) => void];

const UserContext = createContext<UserContextType>([undefined, () => {}]);

export const UserProvider: React.FC = ({ children }) => {
  const userPair = useState<User>();
  return <UserContext.Provider value={userPair}>{children}</UserContext.Provider>;
};

export default UserContext;
