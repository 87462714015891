import useRequest, { RequestResult, Fetch } from "./useRequest";

import { DeviceResult } from "../types";

export default function useDevice(
  id: number,
  params?: Record<string, unknown>,
): [RequestResult<DeviceResult>, Fetch] {
  return useRequest<DeviceResult, any>(`/device/${id}/`, { ...params, expanded: 1 });
}
