import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { HashRouter as Router } from "react-router-dom";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { ThemeProvider } from "@space-apps/honeycomb";

import "./lib/axios";
import "./index.css";

import { RememberMe, Routes } from "./components";
import { UserProvider } from "./contexts/UserContext";

const { REACT_APP_CI_MERGE_REQUEST_ID: theMRId } = process.env;

const App: React.FC = () => {
  return (
    <>
      <CssBaseline />
      <Router basename={theMRId != null ? `/preview/${theMRId}` : undefined}>
        <UserProvider>
          <ThemeProvider>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <RememberMe>
                <Routes />
              </RememberMe>
            </MuiPickersUtilsProvider>
          </ThemeProvider>
        </UserProvider>
      </Router>
    </>
  );
};

export default App;
