import React, { useEffect, useState } from "react";
import { Grid, CircularProgress } from "@material-ui/core";
import axios from "axios";
import { uniq } from "lodash";

import { PartialHive } from "../types";

import { useDeviceList } from "../hooks";
import { hasCamera } from "../util";
import DeviceCard from "./DeviceCard";

type HiveMap = Record<number, PartialHive>;

const DeviceList: React.FC = () => {
  const [{ loading, data }] = useDeviceList();

  const [hiveMap, setHiveMap] = useState<HiveMap>();

  useEffect(() => {
    async function query(): Promise<void> {
      if (!loading && data) {
        const hiveIds = uniq(data.data.filter(hasCamera).map((device) => device.hiveId));
        const hives = await Promise.all(
          hiveIds.map(async (hiveId) => axios.get<PartialHive>(`/hive/${hiveId}/`)),
        );
        const hm = hives.reduce<HiveMap>((acc, curr) => {
          acc[curr.data.id] = curr.data;
          return acc;
        }, {});
        setHiveMap(hm);
      }
    }
    void query();
  }, [loading, data]);

  if (loading || !data || !hiveMap) return <CircularProgress />;

  const devicesWithCamera = data.data.filter(hasCamera).sort((a, b) => a.hiveId - b.hiveId);

  return (
    <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={2}>
      {devicesWithCamera.map((device) => (
        <Grid key={device.id} item>
          <DeviceCard device={device} hive={hiveMap[device.hiveId]} />
        </Grid>
      ))}
    </Grid>
  );
};

export default DeviceList;
