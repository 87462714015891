import React, { useState, useCallback, useEffect, useContext } from "react";
import { Grid, TextField, Button, CircularProgress } from "@material-ui/core";
import axios from "axios";

import { User } from "../types";

import { UserContext } from "../contexts";

const LoginForm: React.FC = () => {
  const [, setUser] = useContext(UserContext);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [pending, setPending] = useState(false);
  const [loading, setLoading] = useState(false);
  const [, setError] = useState<any>();

  const handleSubmit = useCallback((e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setPending(true);
  }, []);

  useEffect(() => {
    async function login(): Promise<void> {
      if (pending && !loading) {
        setLoading(true);
        try {
          const formData = new FormData();
          formData.set("username", email);
          formData.set("password", password);
          const { data } = await axios.post<User>("/session/", formData);
          setUser(data);
        } catch (err) {
          setError(err);
        } finally {
          setPending(false);
          setLoading(false);
        }
      }
    }
    void login();
  }, [pending, loading, email, password, setUser]);

  const disabled = pending || loading;

  return (
    <form onSubmit={handleSubmit}>
      <Grid container direction="column" justify="center" alignItems="center" spacing={2}>
        <Grid item>
          <TextField
            label="Email"
            type="email"
            variant="outlined"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            disabled={disabled}
          />
        </Grid>

        <Grid item>
          <TextField
            label="Password"
            type="password"
            variant="outlined"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
            disabled={disabled}
          />
        </Grid>

        <Grid item>
          <Button variant="contained" color="primary" type="submit" disabled={disabled}>
            {loading && <CircularProgress />}
            Log in
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default LoginForm;
