import React, { forwardRef } from "react";
import { Link as RouterLink, LinkProps as RouterLinkProps } from "react-router-dom";
import { Link as MaterialLink } from "@material-ui/core";

const Link: React.FC<RouterLinkProps> = forwardRef<any, RouterLinkProps>(
  ({ to, children, ...rest }, ref) => {
    return (
      /* eslint-disable-next-line */
      // @ts-ignore
      <MaterialLink ref={ref} component={RouterLink} to={to} {...rest}>
        {children}
      </MaterialLink>
    );
  },
);

export default Link;
