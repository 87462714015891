import isBoolean from "lodash/isBoolean";
import isString from "lodash/isString";
import isNumber from "lodash/isNumber";
import round from "lodash/round";
import format from "date-fns/format";
import compareAsc from "date-fns/compareAsc";

import { MeasurementValue, Measurement, Point, Device, Hive } from "./types";

export const CAMERA_SENSOR_KEYS = ["rec", "picture", "video"];

export const DATE_FORMAT = "y-MM-dd";
export const TIME_FORMAT = "HH:mm";
export const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;

export function compareDates(a: Date | string, b: Date | string): number {
  return compareAsc(new Date(a), new Date(b));
}

export function hasCamera(device: Device | Hive): boolean {
  return device.sensors.some((sensor) => CAMERA_SENSOR_KEYS.includes(sensor.key));
}

interface SensorNameI18n {
  readonly [key: string]: string | undefined;
  readonly Battery: string;
  readonly Humidity: string;
  readonly Temp_load: string;
  readonly Weight: string;
  readonly Wifi: string;
}

const SENSOR_NAME_I18N: SensorNameI18n = {
  Battery: "Akkumulátor",
  Humidity: "Páratartalom",
  Temp_load: "Külső hőmérséklet",
  Weight: "Súly",
  Wifi: "WiFi",
};

export function formatSensorName(name: keyof SensorNameI18n | string): string {
  return SENSOR_NAME_I18N[name] ?? "Unknown";
}

export function sortMeasurementsByDate<V extends MeasurementValue>(
  measurements: Array<Measurement<V>>,
  direction = 1,
): Array<Measurement<V>> {
  return measurements.sort(([a], [b]) => direction * compareDates(a, b));
}

export function formatDate(date: Date | string): string {
  return format(new Date(date), DATE_FORMAT);
}

export function formatTime(time: Date | string): string {
  return format(new Date(time), TIME_FORMAT);
}

export function formatDateTime(date: Date | string): string {
  return format(new Date(date), DATE_TIME_FORMAT);
}

export function formatNumber(number: number): number {
  return round(number, 2);
}

export function isPoint(value: MeasurementValue): value is Point {
  return !isBoolean(value) && !isString(value) && !isNumber(value);
}

export function formatMeasurementValue(value: MeasurementValue): string | number {
  if (isNumber(value)) {
    return formatNumber(value);
  }
  if (isPoint(value)) {
    return `[${value.coordinates[0]}, ${value.coordinates[1]}]`;
  }
  return value.toString();
}
