import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";

import BackButton from "./BackButton";
import LogoutButton from "./LogoutButton";

const Header: React.FC = () => {
  return (
    <AppBar position="sticky">
      <Grid container component={Toolbar} justify="space-between">
        <BackButton edge="start" color="inherit" />
        <LogoutButton edge="end" color="inherit" />
      </Grid>
    </AppBar>
  );
};

export default Header;
