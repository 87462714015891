import React from "react";
import IconButton, { IconButtonProps } from "@material-ui/core/IconButton";
import BackIcon from "@material-ui/icons/ArrowBack";
import { useHistory } from "react-router-dom";

const BackButton: React.FC<IconButtonProps> = (props) => {
  const history = useHistory();
  return (
    <IconButton {...props} aria-label="back" onClick={() => history.goBack()}>
      <BackIcon />
    </IconButton>
  );
};

export default BackButton;
