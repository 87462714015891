import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";

import DeviceList from "./DeviceList";
import LoginForm from "./LoginForm";
import CameraPage from "./CameraPage";
import Page from "./Page";
import ProtectedRoute from "./ProtectedRoute";

const Routes: React.FC = () => {
  return (
    <Switch>
      <ProtectedRoute path="/login">
        <Grid container justify="center" alignItems="center" style={{ height: "100%" }}>
          <Grid item>
            <Paper style={{ padding: 16 }}>
              <LoginForm />
            </Paper>
          </Grid>
        </Grid>
      </ProtectedRoute>

      <ProtectedRoute guard path="/">
        <Page>
          <Switch>
            <Route exact path="/">
              <DeviceList />
            </Route>

            <Route guard exact path="/:deviceId">
              <CameraPage />
            </Route>
          </Switch>
        </Page>
      </ProtectedRoute>

      <Route path="/not-found">
        <Grid container justify="center" alignItems="center" style={{ height: "100%" }}>
          <Grid item>404</Grid>
        </Grid>
      </Route>

      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;
