import React, { useCallback, useEffect, useState, useMemo } from "react";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import startOfDay from "date-fns/startOfDay";
import parse from "date-fns/parse";
import axios from "axios";

import { Device, SensorResult, CameraSensorKey, Hive } from "../types";

import { formatDate, DATE_TIME_FORMAT, formatDateTime, CAMERA_SENSOR_KEYS } from "../util";
import CameraSensorQueryInputGroup, { CameraSensorQueryInput } from "./CameraSensorQueryInputGroup";
import CameraSensorDialog from "./CameraSensorDialog";
import CameraInfo from "./CameraInfo";
import CameraRecordingList from "./CameraRecordingList";

interface Recording {
  sensor: CameraSensorKey;
  date: Date;
  value: string;
}

const Camera: React.FC<{ hive: Hive; device: Device }> = ({ hive, device }) => {
  const [query, setQuery] = useState<{
    sensors: CameraSensorKey[];
    params: { from: Date; to: Date };
  }>();
  const availableSensors = useMemo(
    () => device.sensors.map((s) => s.key).filter((s) => CAMERA_SENSOR_KEYS.includes(s)),
    [device.sensors],
  ) as CameraSensorKey[];

  const [loading, setLoading] = useState(true);
  const [recordings, setRecordings] = useState<Recording[]>([]);

  const handleChange = useCallback(
    (value: CameraSensorQueryInput) => {
      const day = formatDate(startOfDay(value.day));
      const tod = value.tod.split("-");
      const from = parse(`${day} ${tod[0]}`, DATE_TIME_FORMAT, new Date());
      const to = parse(`${day} ${tod[1]}`, DATE_TIME_FORMAT, new Date());
      setQuery({
        sensors: availableSensors,
        params: {
          from,
          to,
        },
      });
    },
    [availableSensors],
  );

  useEffect(() => {
    async function request(): Promise<void> {
      if (query) {
        setLoading(true);
        const responses = await Promise.all(
          query.sensors.map(async (sensorKey) =>
            axios.get<SensorResult<string>>(
              `/device/${
                device.id
              }/sensor/${sensorKey}/measurements/series?date_from=${formatDateTime(
                query.params.from,
              )}&date_to=${formatDateTime(query.params.to)}`,
            ),
          ),
        );
        const rs = responses.reduce<Recording[]>((acc, curr, i) => {
          curr.data.data.forEach(([date, value]) => {
            acc.push({ sensor: query.sensors[i], value, date: new Date(date) });
          });
          return acc;
        }, []);
        setRecordings(rs);
        setLoading(false);
      }
    }
    void request();
  }, [device.id, query]);

  const [dialogState, setDialogState] = useState<{ recording?: Recording; open: boolean }>({
    open: false,
  });
  const handleOpen = useCallback((recording: Recording) => {
    setDialogState({ open: true, recording });
  }, []);
  const handleClose = useCallback(() => {
    setDialogState({ open: false });
  }, []);

  return (
    <>
      <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={1}>
        <Grid item>
          <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={1}>
            <Grid item>
              <CameraInfo hive={hive} device={device} />
            </Grid>

            <Grid item>
              <CameraSensorQueryInputGroup onChange={handleChange} disabled={loading} />
            </Grid>

            {loading && (
              <Grid item>
                <LinearProgress />
              </Grid>
            )}
          </Grid>
        </Grid>

        <Grid item>
          <CameraRecordingList loading={loading} recordings={recordings} onClick={handleOpen} />
        </Grid>
      </Grid>

      <CameraSensorDialog
        open={dialogState.open}
        onClose={handleClose}
        hive={hive}
        device={device}
        recording={
          dialogState.recording && {
            date: dialogState.recording.date,
            key: dialogState.recording.sensor,
            src: dialogState.recording.value,
          }
        }
      />
    </>
  );
};

export default Camera;
