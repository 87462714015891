import React from "react";
import { Card, CardContent, CardActions, Typography } from "@material-ui/core";

import { Device, PartialHive } from "../types";

import ButtonLink from "./ButtonLink";

const DeviceCard: React.FC<{ device: Device; hive: PartialHive }> = ({ device, hive }) => {
  const { id, name, description } = device;
  return (
    <Card variant="outlined">
      <CardContent>
        <Typography color="textSecondary" gutterBottom>
          {hive.name} {hive.description && `- ${hive.description}`}
        </Typography>
        <Typography variant="h5" component="h2">
          {name}
        </Typography>
        <Typography variant="body1" component="p">
          {description}
        </Typography>
        {/* <Typography variant="body2" component="p">
          Serial Number: {serialNumber}
        </Typography>
        <Typography variant="body2" component="p">
          Hardware: {hardwareVersion}
        </Typography> */}
      </CardContent>

      <CardActions>
        <ButtonLink to={`/${id}`} size="small">
          Tovább
        </ButtonLink>
      </CardActions>
    </Card>
  );
};

export default DeviceCard;
