import React, { useState, useCallback, useEffect, useContext } from "react";
import IconButton, { IconButtonProps } from "@material-ui/core/IconButton";
import LogoutIcon from "@material-ui/icons/ExitToApp";
import axios from "axios";

import { User } from "../types";

import { UserContext } from "../contexts";

const LogoutButton: React.FC<IconButtonProps> = (props) => {
  const [, setUser] = useContext(UserContext);

  const [pending, setPending] = useState(false);
  const [loading, setLoading] = useState(false);
  const [, setError] = useState<any>();

  const handleLogout = useCallback(() => {
    setPending(true);
  }, []);

  useEffect(() => {
    async function logout(): Promise<void> {
      if (pending && !loading) {
        setLoading(true);
        try {
          await axios.post<User>("/session/delete/");
          setUser(undefined);
        } catch (err) {
          setError(err);
        } finally {
          setPending(false);
          setLoading(false);
        }
      }
    }
    void logout();
  }, [pending, loading, setUser]);

  const disabled = pending || loading;

  return (
    <IconButton {...props} aria-label="logout" onClick={handleLogout} disabled={disabled}>
      <LogoutIcon />
    </IconButton>
  );
};

export default LogoutButton;
