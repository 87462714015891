import React, { useState, useEffect } from "react";
import { useParams, Redirect } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";

import { Hive } from "../types";

import { useDevice } from "../hooks";
import { hasCamera } from "../util";
import Camera from "./Camera";

const CameraPage: React.FC = () => {
  const { deviceId } = useParams<{ deviceId: string }>();

  const [{ loading: deviceLoading, data: device }] = useDevice(Number.parseInt(deviceId, 10));
  const [{ loading: hiveLoading, data: hive }, setHiveResult] = useState<{
    loading: boolean;
    data?: Hive;
  }>({
    loading: true,
  });
  const loading = deviceLoading || hiveLoading;

  useEffect(() => {
    async function query(): Promise<void> {
      if (!deviceLoading && device) {
        setHiveResult((h) => ({ ...h, loading: true }));
        const res = await axios.get<Hive>(`/hive/${device.hiveId}/`, { params: { expanded: 1 } });
        setHiveResult({ data: res.data, loading: false });
      }
    }
    void query();
  }, [deviceLoading, device]);

  if (loading) return <CircularProgress />;
  if (hive && device && hasCamera(device)) return <Camera hive={hive} device={device} />;
  return <Redirect to="/not-found" />;
};

export default CameraPage;
